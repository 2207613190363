<template>
  <div class="list">
    <div @click="goPS">
      <img src="@/assets/img/ps.png" class="ps" />
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  name: 'List',
  methods: {
    goPS () {
      const cookieUserInfo = Cookies.get('userInfo')
      const u = JSON.parse(cookieUserInfo)
      const { punterMobile, version, equipmentNo } = u
      location.href = `https://h5show.powerss.cc?first=1&phoneNumber=${punterMobile}&version=${version}&equipmentNo=${equipmentNo}`
    }
  }
}
</script>

<style scoped lang="less">
.list {
  background-color: #F4F5F7;
}

.ps {
  width: 750px;
  height: 576px;
}
</style>
